export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED';
export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SET_TOKEN = 'SET_TOKEN';
export const CONNECTION_CHANGED = 'CONNECTION_CHANGED';

export const messageReceived = (message) => {
    return {
        type: MESSAGE_RECEIVED,
        message
    };
};

export const sendMessage = (message) => {
    return {
        type: SEND_MESSAGE_REQUEST,
        message
    };
};

export const setToken = (token) => {
    return {
        type: SET_TOKEN,
        payload: token
    };
};
