import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createBrowserHistory } from "history";

import routerMiddleware from "./middleware/core/router";
import api from "./middleware/core/api";
import storage from "./middleware/core/storage";
import websocket from "./middleware/core/websocket";

import auth from "./middleware/feature/auth";
import packing from "./middleware/feature/packing";
import booking from "./middleware/feature/bookings";
import telehealth from "./middleware/feature/telehealth";
import message from "./middleware/feature/message";
import orders from "./middleware/feature/orders";

import { authReducer } from "./reducers/auth.reducer";
import { packingReducer } from "./reducers/packing.reducer";
import { telehealthReducer } from "./reducers/telehealth.reducer";
import { ordersReducer } from "./reducers/orders.reducer";
import { socketReducer } from "./middleware/core/websocket";
import { bookingsReducer } from "./reducers/bookings.reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    packing: packingReducer,
    bookings: bookingsReducer,
    telehealth: telehealthReducer,
    socketState: socketReducer,
    orders: ordersReducer
});

export const history = createBrowserHistory();

const featureMiddleware = [auth, packing, booking, telehealth, message, orders];

const coreMiddleware = [routerMiddleware(history), api, storage, websocket];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(
  applyMiddleware(...featureMiddleware, ...coreMiddleware)
);

export const store = createStore(rootReducer, {}, enhancer);

window.store = store;
