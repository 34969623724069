import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch } from "react-router-dom";


import { Provider } from "react-redux";
import { history, store } from "./redux/store";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import PackingLayout from "layouts/Packing.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();


ReactDOM.render(
  <Provider store={store}>
      <Router history={history}>
          <Switch>
              <Route path="/auth" component={AuthLayout}/>
              <Route path="/admin" component={AdminLayout}/>
              <Route path="/packing" component={PackingLayout}/>
              <Redirect from="/" to="/packing"/>
          </Switch>
      </Router>
  </Provider>,
  document.getElementById("root")
);
