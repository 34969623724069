import { SET_PRACTITIONERS } from "../actions/telehealth";

const initState = { practitioners: []};

export const telehealthReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_PRACTITIONERS:
      return { ...state, practitioners: action.payload };
    default:
      return state;
  }
};