import React from "react";
import cx from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";

const styles = {};

const useStyles = makeStyles(styles);


export default function OrderActions({ order, onAction }) {

    const classes = useStyles();
    const [showAccept, setShowAccept] = React.useState(false);
    const [showCancel, setShowCancel] = React.useState(false);
    const [showReady, setShowReady] = React.useState(false);
    const [showPaymentReceived, setShowPaymentReceived] = React.useState(false);
    const [showOrderCompleted, setShowOrderCompleted] = React.useState(false);
    const [requestPending, setRequestPending] = React.useState(false);

    React.useEffect(() => {
        const { order_status, payment_status } = order;

        setShowAccept(false);
        setShowCancel(false);
        setShowReady(false);
        setShowPaymentReceived(false);
        setShowOrderCompleted(false);

        setRequestPending(false);

        if (order_status === "Awaiting store acceptance") {
            setShowAccept(true);
            setShowCancel(true);
        }

        else if (order_status === 'Order being prepared') {
            setShowCancel(true);
            setShowReady(true);
        }

        else if (order_status === 'Order ready for collection' || order_status === 'Awaiting delivery') {
            if (payment_status === 'Pending Payment') {
                setShowPaymentReceived(true);
            } else {
                setShowOrderCompleted(true);
            }


            setShowCancel(true);
        }

    }, [order.order_status, order.payment_status]);


    React.useEffect(() => {
        let timer;
        if (requestPending) {
            timer = setTimeout(() => {
                if (requestPending)
                    setRequestPending(false)
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [requestPending]);

    const onButtonPress = (action) => {
        setRequestPending(true);
        onAction(action);
    };

    return (
      <>
          {
              showAccept && <Button
                onClick={() => onButtonPress("order-accepted")}
                color="info"
                disabled={requestPending}
              >
                  Accept Order
              </Button>
          }

          {
              showReady && <Button
                onClick={() => onButtonPress("order-is-ready")}
                color="success"
                disabled={requestPending}
              >
                  Order is Ready
              </Button>
          }


          {
              showPaymentReceived && <Button
                onClick={() => onButtonPress("order-payment-received")}
                color="info"
                disabled={requestPending}
              >
                  Payment Received
              </Button>
          }


          {
              showOrderCompleted && <Button
                onClick={() => onButtonPress("order-completed")}
                color="info"
                disabled={requestPending}
              >
                  Order Completed
              </Button>
          }


          {
              showCancel && <Button
                onClick={() => onButtonPress("order-cancelled")}
                color="danger"
                disabled={requestPending}
              >
                  Cancel Order
              </Button>
          }

      </>
    );
}
