import { apiRequest, API_GET, API_POST } from "../../actions/api";
import {
    FETCH_BOOKINGS,
    PERFORM_BOOKING_ACTION,
    MAKE_BOOKING,
    FETCH_CONSULTS,
    setBookings,
    fetchBookings,
    setBookingsLoading,
    setAlert,
    clearBookingsForm
} from "../../actions/bookings";


const bookings = ({ dispatch, getState }) => next => action => {

    next(action);

    if (!([FETCH_BOOKINGS, PERFORM_BOOKING_ACTION, MAKE_BOOKING, FETCH_CONSULTS].includes(action.type)))
        return;

    switch (action.type) {
        case FETCH_BOOKINGS:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: "bookings",
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setBookings(result);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;

        case FETCH_CONSULTS:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: "consults",
                  method: API_GET,
                  success: (result) => {
                      next(setBookingsLoading(false));
                      return setBookings(result);
                  },
                  failure: () => setBookingsLoading(false)
              })
            );
            break;
        case PERFORM_BOOKING_ACTION:
            next(
              apiRequest({
                  path: `booking/action/${action.payload.bookingId}`,
                  method: API_POST,
                  data: {
                      action: action.payload.action
                  },
                  success: (result) => {
                      if (action.payload.action === "billing-completed" || action.payload.action === "cancel-booking") {
                          dispatch(fetchBookings());
                      }

                      if (action.payload.action === "resend-booking-practitioner" || action.payload.action === "resend-booking-patient") {
                          const messageTo = action.payload.action === "resend-booking-practitioner" ? "practitioner" : "patient";
                          dispatch(setAlert({
                              show: true,
                              type: "success",
                              message: `Booking has been resent sent to the ${messageTo}`,
                              title: "Booking Resent!"
                          }));
                      }
                  },
                  failure: () => {
                      dispatch(fetchBookings());
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error performing the requested action - please try again or contact support.",
                          title: "Failed to perform action!"
                      }));
                  }
              })
            );
            break;

        case MAKE_BOOKING:
            next(setBookingsLoading(true));
            next(
              apiRequest({
                  path: "booking",
                  method: API_POST,
                  data: action.payload,
                  success: () => {
                      next(setBookingsLoading(false));
                      next(clearBookingsForm());
                      dispatch(setAlert({
                          show: true,
                          type: "success",
                          message: "The booking has been created",
                          title: "Booking created!"
                      }));
                  },
                  failure: () => {
                      next(setBookingsLoading(false));
                      dispatch(setAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error creating the booking.",
                          title: "Booking failed to create!"
                      }));
                  }
              })
            );
            break;
        default:
            break;
    }

};

export default bookings;
