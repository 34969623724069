import {
    ACTIVE_ORDERS_RECEIVED, setActiveOrders
} from "../../actions/orders";


const orders = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([ACTIVE_ORDERS_RECEIVED].includes(action.type)))
        return;

    switch (action.type) {
        case ACTIVE_ORDERS_RECEIVED:
            next(setActiveOrders(action.payload.active_orders));
            break;
        default:
            break;
    }

};

export default orders;
