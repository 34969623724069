import { API_POST, apiRequest } from "../../actions/api";
import {
    clearPackingForm,
    setPackingRequestDone,
    setPackingRequestInProgress,
    SUBMIT_PACKING_FORM, setPackingAlert, setPackingSlipResponse
} from "../../actions/packing";


const packing = ({ dispatch, getState }) => next => action => {

    next(action);

    if (!([SUBMIT_PACKING_FORM].includes(action.type)))
        return;

    switch (action.type) {
        case SUBMIT_PACKING_FORM:
            next(setPackingRequestInProgress());
            next(
              apiRequest({
                  path: "create-packing-slips",
                  method: API_POST,
                  data: action.payload,
                  success: (res) => {
                      next(setPackingRequestDone());
                      next(setPackingSlipResponse(res));
                      dispatch(setPackingAlert({
                          show: true,
                          type: "success",
                          message: "The generated packing slips has been downloaded",
                          title: "Request Successful!"
                      }));
                      next(clearPackingForm());
                  },
                  failure: () => {
                      next(setPackingRequestDone());
                      dispatch(setPackingAlert({
                          show: true,
                          type: "warning",
                          message: "There was an error creating the packing slips.",
                          title: "Packing slip generation failed!"
                      }));
                  }
              })
            );
            break;
        default:
            break;
    }

};

export default packing;
