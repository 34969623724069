import {
    CONNECTION_CHANGED,
    MESSAGE_RECEIVED,
    sendMessage,
} from "../../actions/message";

import {
    activeOrdersReceived
} from '../../actions/orders'

export const STATUS = "STATUS";

const messageMiddleware = (store) => {
    let interval = 0;
    return (next) => (action) => {
        switch (action.type) {
            case CONNECTION_CHANGED:
                if (action.connected) {
                    const poll = () => store.dispatch(sendMessage({ action: "status" }));
                    clearInterval(interval);
                    interval = setInterval(poll, 60 * 1000);
                    poll();
                }
                else {
                    clearInterval(interval);
                }
                break;
            case MESSAGE_RECEIVED:
                const { message } = action;
                const { type, payload } = message;
                if (!type)
                    break;
                if (type === STATUS) {
                    store.dispatch(activeOrdersReceived(payload));
                }
                break;
            default:
                break;
        }
        return next(action);
    };
};
export default messageMiddleware;
