export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKINGS_LOADING = 'SET_BOOKINGS_LOADING';
export const SET_ALERT = 'SET_ALERT';
export const PERFORM_BOOKING_ACTION = 'PERFORM_BOOKING_ACTION';
export const MAKE_BOOKING = 'MAKE_BOOKING';
export const SET_BOOKING_FORM_FIELD = 'SET_BOOKING_FORM_FIELD';
export const CLEAR_BOOKINGS_FORM = 'CLEAR_BOOKINGS_FORM ';

export const fetchBookings = () => ({type: FETCH_BOOKINGS});
export const performBookingAction = (bookingId, action) => ({type: PERFORM_BOOKING_ACTION, payload: {bookingId, action}});
export const setBookings = (bookings) => ({type: SET_BOOKINGS, payload: bookings});
export const setBookingsLoading = (value) => ({type: SET_BOOKINGS_LOADING, payload: value});
export const setAlert = (alert) => ({type: SET_ALERT, payload: alert});
export const makeBooking = (booking) => ({type: MAKE_BOOKING, payload: booking});
export const setBookingFormField = (field, value) => ({type: SET_BOOKING_FORM_FIELD, payload: {field, value}});
export const clearBookingsForm = () => ({type: CLEAR_BOOKINGS_FORM});


export const FETCH_CONSULTS = 'FETCH_CONSULTS';
export const fetchConsults = () => ({type: FETCH_CONSULTS});
