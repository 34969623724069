import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import OrderModal from "views/Orders/OrderModal";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import moment from "moment";

// import { performBookingAction, setAlert } from "redux/actions/bookings";

import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "../../assets/jss/material-dashboard-pro-react/components/buttonStyle";


const styles = {
    cardIconTitle: {
        ...cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    },
    action: {
        display: "flex",
        alignItems: "center"
    },
    actionText: {
        paddingLeft: 15
    },
    tableText: {
        margin: 0
    },
    tableSubtext: {
        margin: 0,
        fontSize: "0.8em"
    },
    alertContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    ...buttonStyle
};

const useStyles = makeStyles(styles);


const formatMoney = function(n, decPlaces, thouSeparator, decSeparator) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSeparator = decSeparator === undefined ? "." : decSeparator;
    thouSeparator = thouSeparator === undefined ? "," : thouSeparator;

    let sign = n < 0 ? "-" : "";
    let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
    let j = i.length > 3 ? i.length % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
};

const isActionNeeded = (order) => {
    if (order.order_status === "Awaiting store acceptance")
        return true;

    return false;
};

export default function ReactTables() {

    // const dispatch = useDispatch();
    const location = useLocation();

    const [isActiveOnly, setIsActiveOnly] = React.useState(false);
    const [title, setTitle] = React.useState("Active Orders");

    React.useEffect(() => {
        if (location.pathname === "/admin/active_orders") {
            setIsActiveOnly(true);
            setTitle("Active Orders");
        } else {
            setIsActiveOnly(false);
            setTitle("All Orders");

        }
    }, [location]);

    const uiLoading = useSelector(state => state.orders.loading);
    const orders = useSelector(state => state.orders.activeOrders);
    const ordersByID = useSelector(state => state.orders.activeOrdersByID);
    const [selectedOrderID, setSelectedOrderID] = React.useState(null);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const alert = useSelector(state => state.bookings.alert);

    React.useEffect(() => {
        const filteredOrders = orders.filter((order) => {
            if (isActiveOnly) {
                if (["Complete", "Cancelled"].includes(order.order_status)) {
                    return false;
                }
            }
            return true;
        });

        setData(
          filteredOrders.map((order, key) => (
            {
                id: order.order_id,
                customer_name: order.customer_name,
                customer_name_render: (
                  <div>
                      <p style={styles.tableText}>{order.customer_name}</p>
                      <p style={styles.tableSubtext}>{order.customer_email}</p>
                  </div>
                ),
                customer_contact_number: order.customer_contact_number,
                order_datetime: order.order_datetime,
                payment: (<div>
                    <p style={styles.tableText}>{"R " + formatMoney(order.total)}</p>
                    <p style={styles.tableSubtext}>{order.payment_method}</p>
                </div>),
                total: order.total,
                payment_method: order.payment_method,
                status: order.order_status,
                payment_status: order.payment_status,
                action_needed: isActionNeeded(order)
            }
          ))
        );
    }, [orders, isActiveOnly]);

    React.useEffect(() => {
        if (selectedOrderID) {
            setSelectedOrder(ordersByID[selectedOrderID]);
        } else {
            setSelectedOrder(null);
        }
    }, [selectedOrderID, ordersByID]);

    const [data, setData] = React.useState([]);

    const renderAlert = () => {

        if (!alert.show) return null;

        return (
          <SweetAlert
            success={alert.type === "success"}
            warning={alert.type === "warning"}
            style={{ display: "block", marginTop: "-100px" }}
            title={alert.title}
            // onConfirm={() => dispatch(setAlert({ show: false }))}
            // onCancel={() => dispatch(setAlert({ show: false }))}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
              {alert.message}
          </SweetAlert>
        );
    };

    const onOderSelected = (orderID) => {
        setSelectedOrderID(orderID);
    };

    const renderOrderModal = () => {
        if (!selectedOrder) return null;

        return <OrderModal order={selectedOrder} onClose={() => setSelectedOrderID(null)}/>;
    };
    const classes = useStyles();
    return (
      <GridContainer>
          {
              renderAlert()
          }
          {
              renderOrderModal()
          }

          <GridItem xs={12}>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <Assignment/>
                      </CardIcon>
                      <div style={{ display: "flex", alignItems: "center", margin: 15 }}>
                          <h4 className={classes.cardIconTitle} style={{ margin: 5 }}>{title}</h4>
                          {
                              uiLoading && <Loader
                                type="ThreeDots"
                                color="#00acc1"
                                height={100}
                                width={100}
                                timeout={15000} //15 secs
                                style={{ paddingLeft: "15" }}
                              />
                          }

                      </div>
                  </CardHeader>
                  <CardBody>
                      <ReactTable
                        data={data}
                        filterable={true}
                        defaultSorted={[{ id: "action_needed", desc: true }]}
                        columns={[
                            {
                                Header: "",
                                accessor: "action_needed",
                                width: 50,
                                Cell: row => (
                                  row.original.action_needed ? <div className={classes.alertContainer}>
                                      <svg height="10" width="10" className="blinking">
                                          <circle cx="5" cy="5" r="5" fill="#d50000"/>
                                      </svg>
                                  </div> : null
                                )
                            },
                            {
                                Header: "Order Number",
                                accessor: "id"
                            },
                            {
                                Header: "Customer",
                                accessor: "customer_name_render",
                                filterMethod: (filter, row) => {
                                    return row._original.customer_name.toLowerCase().includes(filter.value.toLowerCase());
                                }
                            },
                            {
                                Header: "Status",
                                accessor: "status"
                            },
                            {
                                Header: "Time",
                                accessor: "order_datetime",
                                Cell: row => moment(row.original.order_datetime).fromNow(),
                                sortMethod: (a, b) => {
                                    if (a === b) {
                                        return 0;
                                    }

                                    return a > b ? 1 : -1;
                                },
                                filterable: false

                            },
                            {
                                Header: "Total",
                                accessor: "total",
                                Cell: row => (
                                  <div>
                                      <p style={styles.tableText}>{"R " + formatMoney(row.original.total)}</p>
                                      <p style={styles.tableSubtext}>{row.original.payment_method}</p>
                                  </div>
                                ),
                                filterable: false
                            },
                            {
                                Header: "Payment Status",
                                accessor: "payment_status",
                                filterable: false
                            }
                        ]}
                        defaultPageSize={10}
                        showPaginationTop={false}
                        showPaginationBottom={true}
                        getTrProps={(state, rowInfo, column) => {
                            return {
                                onClick: (e, handleOriginal) => {
                                    setSelectedOrderID(rowInfo.original.id);
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                }
                            };
                        }}
                        className="-striped -highlight"
                      />
                  </CardBody>
              </Card>
          </GridItem>
      </GridContainer>
    );
}
