import React from "react";
import cx from "classnames";
import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";

import { Divider } from "@material-ui/core";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import LineItem from "views/Orders/LineItem";

const styles = {
    infoBlock: {
        minHeight: 20,
        display: "flex",
        flexDirection: "column",
        padding: 10,
        margin: 5
    },
    infoBlockPrimary: {
        backgroundColor: "#f3fafa",
        borderTop: "4px solid #1ECECA"
    },
    infoTitle: {
        margin: 0,
        fontWeight: 600,
        fontSize: "0.9em",
        color: "#8b8b8b"
    },
    infoContent: {
        margin: 0,
        fontWeight: 500
    },
    text: {
        margin: 0
    },
    details: {
        marginTop: 15,
        "& p": {
            margin: 0,
            fontWeight: 400
        }
    },
    highlight: {
        color: '#0d47a1',
    }
};

const useStyles = makeStyles(styles);


const formatMoney = function(n, decPlaces, thouSeparator, decSeparator) {
    decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
    decSeparator = decSeparator === undefined ? "." : decSeparator;
    thouSeparator = thouSeparator === undefined ? "," : thouSeparator;

    let sign = n < 0 ? "-" : "";
    let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + "";
    let j = i.length > 3 ? i.length % 3 : 0;
    return sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : "");
};

export default function Order({ order }) {

    const classes = useStyles();

    return (
      <GridContainer spacing={2}>

          <GridItem xs={12}>

              <div className={cx(classes.infoBlock, classes.infoBlockPrimary)}>
                  <p className={classes.infoTitle}>ORDER NUMBER</p>
                  <p className={classes.infoContent}>{order.order_id}</p>
              </div>
          </GridItem>

          <GridItem xs={6}>
              <div className={classes.infoBlock}>
                  <p className={classes.infoTitle}>ORDER STATUS</p>

                  <p className={cx(classes.infoContent, classes.highlight)}>{order.order_status}</p>
              </div>
          </GridItem>

          <GridItem xs={6}>
              <div className={classes.infoBlock}>
                  <p className={classes.infoTitle}>PAYMENT METHOD</p>

                  <p className={classes.infoContent}>{order.payment_method}</p>
              </div>
          </GridItem>

          <Divider style={{ width: "100%" }}/>

          <GridItem xs={12}>
              {
                  order.line_items.map((item, idx) => <LineItem item={item} key={idx}/>)
              }

          </GridItem>
          <Divider style={{ width: "100%" }}/>

          <GridItem xs={6}>

              <div className={cx(classes.infoBlock, classes.infoBlockPrimary)}>
                  <p className={classes.infoTitle}>PAYMENT STATUS</p>
                  <p className={classes.infoContent}>{order.payment_status}</p>
              </div>
          </GridItem>

          <GridItem xs={6}>
              <div className={cx(classes.infoBlock, classes.infoBlockPrimary)}>
                  <p className={classes.infoTitle}>TOTAL</p>

                  <p className={classes.infoContent}>{"R " + formatMoney(order.total)}</p>
              </div>
          </GridItem>

          <Divider style={{ width: "100%" }}/>

          <GridItem xs={12}>
              <div className={classes.details}>
                  <p>Order
                      for {order.store_name} by {order.customer_name} on {moment(order.order_datetime).format("YYYY-MM-DD [at] HH:mm")}</p>
                  <p>{order.customer_contact_number}</p>
                  <p>{order.customer_email}</p>
              </div>
          </GridItem>
      </GridContainer>
    );
}
