import moment from "moment/moment";

import {
    SET_ALERT,
    SET_BOOKINGS,
    SET_BOOKINGS_LOADING,
    SET_BOOKING_FORM_FIELD,
    CLEAR_BOOKINGS_FORM
} from "../actions/bookings";
import { AUTH_LOGOUT_USER } from "../actions/auth";

const getRoundedTime = () => {
    const start = moment();
    const remainder = 5 - (start.minute() % 5);

    const dateTime = moment(start).add(remainder, "minutes").seconds(0);
    return dateTime;
};

const defaultBookingsForm = {
    paymentMethod: "private",
    selectedPractitioner: "",
    datetime: getRoundedTime(),
    name: "",
    email: "",
    contactNumber: ""
};

const initState = {
    bookingsList: [],
    bookingsLoading: false,
    alert: { show: false },
    bookingFormFields: { ...defaultBookingsForm }
};

export const bookingsReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_BOOKINGS:
            return { ...state, bookingsList: action.payload.bookings };
        case SET_BOOKINGS_LOADING:
            return { ...state, bookingsLoading: action.payload };
        case SET_ALERT:
            return { ...state, alert: action.payload };
        case SET_BOOKING_FORM_FIELD:
            return {
                ...state,
                bookingFormFields: { ...state.bookingFormFields, [action.payload.field]: action.payload.value }
            };
        case CLEAR_BOOKINGS_FORM:
            return { ...state, bookingFormFields: { ...defaultBookingsForm } };
        case AUTH_LOGOUT_USER:
            return { ...state, bookingsList: [], bookingsLoading: false };
        default:
            return state;
    }
};