/*eslint-disable*/
import React from "react";
import {useDispatch} from 'react-redux';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// core components

import Button from "components/CustomButtons/Button.js";
import Order from "views/Orders/Order";
import OrderActions from "views/Orders/OrderActions";

import styles from "views/Orders/orderModalStyle";
import { sendMessage } from "../../redux/actions/message";


const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export default function OrderModal({ order, onClose }) {
    const classes = useStyles();

    const dispatch = useDispatch();

    return (

      <Dialog
        classes={{
            root: classes.center + " " + classes.modalRoot,
            paper: classes.modal
        }}
        open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"
      >
          <DialogTitle
            id="notice-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
              <Button
                justIcon
                className={classes.modalCloseButton}
                key="close"
                aria-label="Close"
                color="transparent"
                onClick={onClose}
              >
                  <Close className={classes.modalClose}/>
              </Button>
              <h4 className={classes.modalTitle}>Order for {order.customer_name}</h4>
          </DialogTitle>
          <DialogContent
            id="notice-modal-slide-description"
            className={classes.modalBody}
          >
              <Order order={order}/>
          </DialogContent>
          <DialogActions
            className={
                classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
              <OrderActions order={order} onAction={(action) => {
                  dispatch(sendMessage({ action: "action", order_action: action, order_id: order.order_id}))
              }}/>
          </DialogActions>
      </Dialog>
    );
}
