import { useDropzone } from "react-dropzone";
import React, { useMemo } from "react";

const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out"
};
const activeStyle = {
    borderColor: "#2196f3"
};
const acceptStyle = {
    borderColor: "#00e676"
};
const rejectStyle = {
    borderColor: "#ff1744"
};

export function StyledDropzone({ onDrop }) {
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        fileRejections,
        inputRef
    } = useDropzone({ accept: ".csv", maxFiles: 1, onDrop });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);


    const acceptedFileItems = acceptedFiles.map(file => (
      <li key={file.path}>
          {file.path} - {file.size} bytes
      </li>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
          {file.path} - {file.size} bytes
          <ul>
              {errors.map(e => (
                <li key={e.code}>{e.message}</li>
              ))}
          </ul>
      </li>
    ));

    return (
      <div className="container">
          <div {...getRootProps({ style })}>
              <input {...getInputProps()} />
              <p>Drag and drop a csv file here, or click to select</p>
          </div>
          <aside>
              {
                  acceptedFileItems.length >= 1 && <>
                      <h4>Valid files</h4>
                      <ul>{acceptedFileItems}</ul>
                  </>
              }

              {fileRejectionItems.length >= 1 && <>
                  <h4>Rejected files</h4>
                  <ul>{fileRejectionItems}</ul>
              </>}

          </aside>
      </div>
    );
}