export const CONNECTION_CHANGED = "CONNECTION_CHANGED";
export const REQUEST_SOCKET_CONNECT = "REQUEST_SOCKET_CONNECT";
export const REQUEST_SOCKET_DISCONNECT = "REQUEST_SOCKET_DISCONNECT";
export const CONNECT_SOCKET = "CONNECT_SOCKET";
export const DISCONNECT_SOCKET = "DISCONNECT_SOCKET";

export const connectionChanged = (isConnected) => {
    return {
        type: CONNECTION_CHANGED,
        connected: isConnected,
        isError: false
    };
};

export const connectSocket = (token) => {
    return {
        type: CONNECT_SOCKET,
        payload: token
    };
};

export const disconnectSocket = () => {
    return {
        type: DISCONNECT_SOCKET
    };
};

export const requestSocketConnect = (token) => ({ type: REQUEST_SOCKET_CONNECT, payload: token });
export const requestSocketDisonnect = () => ({ type: REQUEST_SOCKET_DISCONNECT });
