import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

const getImageUrl = (url) => {
    if (url.includes("http")) return url;
    return `https://sugarlicious.mobi${url}`;
};




//            {
//                   product: {
//                       id: "gmc-pack-10",
//                       name: "10 x Ganache Macarons",
//                       product_type: "pack",
//                       pack: {
//                           selection_category_id: "gmc-singles",
//                           pricing_method: "fixed",
//                           pack_size: 10
//                       },
//                       category_id: "gmc",
//                       short_description: "Our Classic Ganache Macarons are filled with a variety of flavoured chocolate ganache.",
//                       description: "Our Classic Ganache Macarons are filled with a variety of flavoured chocolate ganache.",
//                       price: 130,
//                       visible: true
//                   },
//                   quantity: 1,
//                   line_subtotal: 130,
//                   line_packaging: 0,
//                   line_total: 130,
//                   selections: [
//                       {
//                           product: {
//                               id: "gmc-singles-bounty",
//                               name: "Bounty",
//                               product_type: "basic",
//                               category_id: "gmc-singles",
//                               short_description: "Classic Macaron filled with Bounty flavoured chocolate ganache",
//                               description: "Classic Macaron filled with Bounty flavoured chocolate ganache",
//                               price: 13,
//                               visible: true
//                           },
//                           quantity: 6
//                       },
//                       {
//                           product: {
//                               id: "gmc-singles-strawberry",
//                               name: "Strawberry",
//                               product_type: "basic",
//                               category_id: "gmc-singles",
//                               short_description: "Classic Macaron filled with Strawberry flavoured chocolate ganache",
//                               description: "Classic Macaron filled with Strawberry flavoured chocolate ganache",
//                               price: 13,
//                               visible: true
//                           },
//                           quantity: 4
//                       }
//                   ]
//               }


const styles = {
    lineItem: {
        display: "grid",
        gridTemplateRows: "auto",
        gridTemplateColumns: "auto minmax(0, 1fr)",

        margin: "0 auto 15px"
    },
    lineItemHead: {
        gridRow: 1,
        gridColumn: 2,
        display: "grid",
        gridTemplateColumns: "minmax(0, 1fr) auto",
        paddingLeft: 15,
        paddingTop: 5,
        borderBottom: "1px dotted #E6E6E6"
    },
    lineItemTitle: {
        textAlign: "left",
        fontWeight: 500,
        fontSize: "1.2em",
        margin: 0
    },
    lineItemPrice: {
        fontSize: "1.2em",
        margin: 0,
        fontWeight: 500,
        textAlign: "right",
        paddingRight: 15

    },
    lineItemImage: {
        gridColumn: 1,
        gridRow: 1,
        height: "100%",
        minHeight: 100,
        width: 100,
        minWidth: 100,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    muted: {
        color: "#999999"
    },
    selectionText: {
        textAlign: "left",
        margin: 0,
        paddingLeft: 25
    }
};

const useStyles = makeStyles(styles);

const PackSelections = ({ selections }) => {

    const classes = useStyles();

    return (
      <div>
          {
              selections.map((selection, idx) => (
                <div key={idx} >
                    <p className={classes.selectionText}>{selection.quantity} x {selection.product.name}</p>
                </div>
              ))
          }

      </div>
    );
};

const LineItem = (
  {
      item: { product, line_total, price, short_description, quantity, selections }
  }) => {
    const classes = useStyles();

    return (
      <div className={classes.lineItem}>
          <div>
              <div className={classes.lineItemHead}>
                  <p className={classes.lineItemTitle}>{quantity} x
                      <span className={classes.muted}> {product.name}</span>
                  </p>
                  <p className={classes.lineItemPrice}>R {(line_total).toFixed(2)}</p>
                  {
                      product.product_type === "pack" && <PackSelections selections={selections}/>
                  }
              </div>
          </div>

          <div
            style={{ backgroundImage: `url(${getImageUrl(`/images/products/${product.id}`)})` }}
            className={classes.lineItemImage}/>

      </div>
    );
};

export default LineItem;