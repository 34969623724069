import {apiRequest, API_GET, API_POST} from '../../actions/api'
import {
  FETCH_PRACTICES, setPractitioners
} from "../../actions/telehealth";


const getPractitionersFormPractices = (practices) => {
    let practitioners = [];

    practices.forEach((practice) => {

        practice.practitioners.forEach((practitioner) => {
            practitioners.push({
                ...practitioner,
                uid: `/${practice.organization.id}/${practice.site.id}/${practice.id}/${practitioner.id}`,
                practice
            })
        });
    });

    return practitioners;
};

const telehealth = ({dispatch, getState}) => next => action => {

    next(action);

    if (!([FETCH_PRACTICES].includes(action.type)))
        return;

    switch (action.type) {
        case FETCH_PRACTICES:
            next(
                apiRequest({
                    path: 'practices',
                    method: API_GET,
                    success: (result) => setPractitioners(getPractitionersFormPractices(result.practices))
                })
            );
            break;
        default:
            break;
    }

};

export default telehealth;
