import jwtDecode from "jwt-decode";

import { apiRequest, API_GET, API_POST, API_AUTH_ERROR, setHeader, clearHeader } from "../../actions/api";
import {
    AUTH_LOGIN,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT_USER,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE,
    AUTHENTICATED_USER_FETCHED_FROM_STORAGE,
    AUTH_LOGIN_FAILED,
    AUTH_RESET_PASSWORD,
    userFetchedFromStorage,
    loginSuccess,
    loginFailure,
    setLoginInfo,
    logoutUser,
    setLoggingIn
} from "../../actions/auth";

import {disconnectSocket} from "../../actions/websocket";

import { saveToLocalStorage, fetchFromLocalStorage, removeFromLocalStorage } from "../../actions/storage";

import { DATA_TYPE_JSON } from "../core/storage";
import { push } from "../core/router";

import { AUTH_URL, AUTH_CLIENT_ID, AUTH_AUDIENCE, AUTH_CONNECTION } from "../../consts";

const ACTION_TYPES_TO_PROCESS = [AUTH_LOGIN, AUTH_LOGIN_SUCCESS, API_AUTH_ERROR, AUTH_LOGOUT_USER,
    FETCH_AUTHENTICATED_USER_FROM_STORAGE, AUTHENTICATED_USER_FETCHED_FROM_STORAGE, AUTH_LOGIN_FAILED, AUTH_RESET_PASSWORD];

const auth = ({ dispatch, getState }) => next => action => {
    if (!action)
        return;

    next(action);

    if (!(ACTION_TYPES_TO_PROCESS.includes(action.type)))
        return;

    switch (action.type) {
        case AUTH_LOGIN:
            next(setLoggingIn(true));
            next(
              apiRequest({
                  full_url: `${AUTH_URL}/oauth/token`,
                  method: API_POST,
                  data: {
                      grant_type: "password",
                      username: action.payload.email,
                      password: action.payload.password,
                      audience: AUTH_AUDIENCE,
                      scope: "openid",
                      client_id: AUTH_CLIENT_ID
                  },
                  success: loginSuccess,
                  failure: loginFailure
              })
            );
            break;
        case AUTH_LOGIN_SUCCESS:

            const loginInfo = {
                access_token: action.payload.access_token,
                id_token: action.payload.id_token,
                user: jwtDecode(action.payload.id_token),
                token_info: jwtDecode(action.payload.access_token)
            };

            next(setLoggingIn(false));
            next(setHeader({ key: "Authorization", value: action.payload.access_token }));
            next(setLoginInfo(loginInfo));
            next(saveToLocalStorage({ key: "user", type: DATA_TYPE_JSON, value: loginInfo }));

            next(push("/"));
            break;
        case AUTH_LOGIN_FAILED:
            next(setLoggingIn(false));
            break;
        case AUTH_LOGOUT_USER:
            next(removeFromLocalStorage("user"));
            next(clearHeader("Authorization"));
            next(setLoginInfo({}));
            next(disconnectSocket());
            next(push("/auth/login"));
            break;
        case API_AUTH_ERROR:
            dispatch(logoutUser());
            break;
        case FETCH_AUTHENTICATED_USER_FROM_STORAGE:
            next(fetchFromLocalStorage({ key: "user", type: DATA_TYPE_JSON, onFetch: userFetchedFromStorage }));
            break;
        case AUTHENTICATED_USER_FETCHED_FROM_STORAGE:
            if (action.payload) {
                next(setLoginInfo(action.payload || {}));
                next(setHeader({ key: "Authorization", value: action.payload.access_token }));
            } else {
                dispatch(logoutUser());
            }
            break;

        case AUTH_RESET_PASSWORD:
            next(
              apiRequest({
                  full_url: `${AUTH_URL}/dbconnections/change_password`,
                  method: API_POST,
                  data: {
                      email: action.payload,
                      connection: AUTH_CONNECTION,
                      client_id: AUTH_CLIENT_ID
                  },
              })
            );
            break;
        default:
            break;
    }

};

export default auth;
