export const SET_PACKING_ALERT = 'SET_PACKING_ALERT';
export const SET_PACKING_FORM_FIELD = 'SET_PACKING_FORM_FIELD';
export const CLEAR_PACKING_FORM = 'CLEAR_PACKING_FORM ';
export const SUBMIT_PACKING_FORM = 'SUBMIT_PACKING_FORM ';
export const SET_PACKING_REQUEST_IN_PROGRESS = 'SET_PACKING_REQUEST_IN_PROGRESS ';
export const SET_PACKING_REQUEST_DONE = 'SET_PACKING_REQUEST_DONE';
export const SET_PACKING_SLIP_RESPONSE = 'SET_PACKING_SLIP_RESPONSE';

export const setPackingAlert = (alert) => ({type: SET_PACKING_ALERT, payload: alert});
export const setPackingRequestInProgress = () => ({type: SET_PACKING_REQUEST_IN_PROGRESS});
export const setPackingRequestDone = () => ({type: SET_PACKING_REQUEST_DONE});
export const setPackingFormField = (field, value) => ({type: SET_PACKING_FORM_FIELD, payload: {field, value}});
export const clearPackingForm = () => ({type: CLEAR_PACKING_FORM});
export const setPackingSlipResponse = (resp) => ({type: SET_PACKING_SLIP_RESPONSE, payload: resp})
export const submitPackingForm = (payload) => ({type: SUBMIT_PACKING_FORM, payload});
