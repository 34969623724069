import React, { useCallback, useRef } from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import EventIcon from "@material-ui/icons/Event";

import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/bookingFormStyle.js";
import { StyledDropzone } from "./FilePicker";
import { clearPackingForm, setPackingAlert, setPackingFormField, submitPackingForm } from "../../redux/actions/packing";


const useStyles = makeStyles(styles);

function download(url, filename) {
    fetch(url).then(function(t) {
        return t.blob().then((b) => {
              var a = document.createElement("a");
              a.href = URL.createObjectURL(b);
              a.setAttribute("download", filename);
              a.click();
          }
        );
    });
}

export default function ExtendedForms() {

    const dispatch = useDispatch();
    const input = useRef();

    const alert = useSelector(state => state.packing.alert);
    const uploadInProgress = useSelector(state => state.packing.busy);
    const packingSlipResponse = useSelector(state => state.packing.packingSlipResponse);

    const formFields = useSelector(state => state.packing.packingFormField);


    const isInvalid = (formFields["csv"] === null);

    React.useEffect(() => dispatch(clearPackingForm()), []);

    React.useEffect(() => {
        if (packingSlipResponse && packingSlipResponse.data) {
            download(packingSlipResponse.data, `Packing Slips - ${new Date(formFields['delivery_date']).toDateString()}.pdf`);
        }

    }, [packingSlipResponse])

    const classes = useStyles();

    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const dataUrl = reader.result;
                dispatch(setPackingFormField("csv", dataUrl));
                input.current = acceptedFiles;
            };
            reader.readAsDataURL(file);
        });

    }, []);

    const handleSubmit = () => {
        input.current.length = 0;
        input.current.splice(0, input.current.length);
        dispatch(submitPackingForm(formFields));
    };


    const renderAlert = () => {

        if (!alert.show) return null;

        return (
          <SweetAlert
            success={alert.type === "success"}
            warning={alert.type === "warning"}
            style={{ display: "block", marginTop: "-100px" }}
            title={alert.title}
            onConfirm={() => dispatch(setPackingAlert({ show: false }))}
            onCancel={() => dispatch(setPackingAlert({ show: false }))}
            confirmBtnCssClass={classes.button + " " + classes.success}
          >
              {alert.message}
          </SweetAlert>
        );
    };


    const renderForm = () => {

        return (
          <form>

              {/*<InputLabel className={classes.label}>Select the delivery date</InputLabel>*/}
              {/*<br/>*/}
              {/*<FormControl fullWidth>*/}
              {/*    <Datetime*/}
              {/*      inputProps={{ placeholder: "" }}*/}
              {/*      value={formFields["datetime"]}*/}
              {/*      onChange={(dt) => dispatch(setPackingFormField("delivery_date", dt))}*/}
              {/*      dateFormat={"dddd, MMMM Do YYYY"}*/}
              {/*      timeFormat={false}*/}
              {/*      closeOnSelect={true}*/}
              {/*    />*/}
              {/*</FormControl>*/}
              {/*<br/>*/}
              {/*<br/>*/}

              <InputLabel className={classes.label}>Select the <b>&nbsp;Data Export&nbsp;</b> CSV</InputLabel>

              <br/>
              <FormControl>
                  <StyledDropzone onDrop={onDrop}/>
              </FormControl>
              <br/>
              <br/>
              <Button color="rose" onClick={handleSubmit}
                      disabled={isInvalid || uploadInProgress}>Upload</Button>
          </form>
        );
    };

    return (
      <div>
          {renderAlert()}
          <GridContainer>
              <Card>
                  <CardHeader color="rose" icon>
                      <CardIcon color="rose">
                          <EventIcon/>
                      </CardIcon>
                      <h4 className={classes.cardIconTitle}>Create Packing Slips</h4>
                  </CardHeader>
                  <CardBody>
                      {renderForm()}
                  </CardBody>
              </Card>

          </GridContainer>
          <GridContainer>
          </GridContainer>
      </div>
    );
}
