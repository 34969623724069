import Orders from "views/Orders/Orders";
import LoginPage from "views/Pages/LoginPage.js";
import PackingForm from "./views/Packing/PackingForm";


// @material-ui/icons

import EventIcon from "@material-ui/icons/Event";
import FormatListNumbered from "@material-ui/icons/FormatListNumbered";
import ListIcon from "@material-ui/icons/List";

const storeClerkFilter = (roles) => roles.some(r => r.startsWith("orders:/"));
const packingFilter = (roles) => roles.some(r => r.startsWith("packs:/"));

const routes = [
    {
        path: "/active_orders",
        name: "Active Orders",
        icon: ListIcon,
        component: Orders,
        layout: "/admin",
        filter: storeClerkFilter
    },
    {
        path: "/all_orders",
        name: "All Orders",
        icon: EventIcon,
        component: Orders,
        layout: "/admin",
        filter: storeClerkFilter
    },
    {
        path: "/create_slip",
        name: "Packing Slips",
        icon: FormatListNumbered,
        component: PackingForm,
        layout: "/packing",
        filter: packingFilter
    },
    {
        path: "/login",
        name: "Login Page",
        mini: "L",
        component: LoginPage,
        layout: "/auth",
        redirect: true
    }
];
export default routes;
