import {
    SET_ACTIVE_ORDERS
} from "../actions/orders";


function pluck(obj, propX) {
    let ret = {};
    for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (prop === propX) {
                continue;
            }
            ret[prop] = obj[prop];
        }
    }
    return ret;
}

export const normalize = (data, key = "id", pluckKey = false) => {
    let ret = {};
    data.forEach((item) => {
        if (item[key]) {
            if (pluckKey)
                ret[item[key]] = pluck(item, key);
            else
                ret[item[key]] = item;
        }
    });
    return ret;
};




const initState = {
    activeOrders: [],
    activeOrdersByID: {},
    loading: false
};

export const ordersReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_ACTIVE_ORDERS:


            return { ...state, activeOrders: action.payload, activeOrdersByID: normalize(action.payload, "order_id") };
        default:
            return state;
    }
};