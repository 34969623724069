import {
    CLEAR_PACKING_FORM,
    SET_PACKING_ALERT,
    SET_PACKING_FORM_FIELD,
    SET_PACKING_REQUEST_DONE,
    SET_PACKING_REQUEST_IN_PROGRESS,
    SET_PACKING_SLIP_RESPONSE
} from "../actions/packing";
import { AUTH_LOGOUT_USER } from "../actions/auth";


const defaultPackingForm = {
    delivery_date: null,
    csv: null
};

const initState = {
    alert: { show: false },
    packingFormField: { ...defaultPackingForm },
    busy: false,
    packingSlipResponse: null
};

export const packingReducer = (state = initState, action) => {
    switch (action.type) {
        case SET_PACKING_ALERT:
            return { ...state, alert: action.payload };
        case SET_PACKING_FORM_FIELD:
            return {
                ...state,
                packingFormField: { ...state.packingFormField, [action.payload.field]: action.payload.value }
            };
        case CLEAR_PACKING_FORM:
            return { ...state, packingFormField: { ...defaultPackingForm }, busy: false };
        case SET_PACKING_REQUEST_IN_PROGRESS:
            return { ...state, busy: true };
        case SET_PACKING_REQUEST_DONE:
            return { ...state, busy: false };
        case AUTH_LOGOUT_USER:
            return { ...state, packingFormField: { ...defaultPackingForm }, busy: false, packingSlipResponse: null };
        case SET_PACKING_SLIP_RESPONSE:
            return { ...state, packingSlipResponse: action.payload };
        default:
            return state;
    }
};