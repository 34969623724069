import {
    REQUEST_SOCKET_CONNECT,
    REQUEST_SOCKET_DISCONNECT,
    CONNECT_SOCKET,
    CONNECTION_CHANGED,
    DISCONNECT_SOCKET,
    connectionChanged,
    connectSocket,
    disconnectSocket
} from "../../actions/websocket";
import { messageReceived, SEND_MESSAGE_REQUEST } from "../../actions/message";
import { WEBSOCKET_ROOT_URL } from "../../consts";

const WEBSOCKET_URL = WEBSOCKET_ROOT_URL;
const MAX_RECONNECTS = 50;
const socketMiddleware = (store) => {

    let token = "";
    let reconnectCount = 0;

    const onConnectionChange = (isConnected) => store.dispatch(connectionChanged(isConnected));
    const onIncomingMessage = (message) => store.dispatch(messageReceived(message));

    const onDisconnect = () => setTimeout(() => {

        const shouldReconnect = store.getState().socketState.shouldConnect;
        if (shouldReconnect && reconnectCount < MAX_RECONNECTS) {
            reconnectCount++;
            store.dispatch(connectSocket(token));
        }

    }, 5 * 1000);

    const socket = new Socket(onConnectionChange, onIncomingMessage, onDisconnect);
    return (next) => (action) => {
        switch (action.type) {
            case REQUEST_SOCKET_CONNECT:
                token = action.payload;
                store.dispatch(connectSocket(token));
                break;
            case REQUEST_SOCKET_DISCONNECT:
                store.dispatch(disconnectSocket());
                break;
            case CONNECT_SOCKET:
                socket.connect(`${WEBSOCKET_URL}/?token=${action.payload}`);
                break;
            case DISCONNECT_SOCKET:
                socket.disconnect();
                break;
            case SEND_MESSAGE_REQUEST:
                socket.sendMessage(Object.assign(Object.assign({}, action.message), { token }));
                break;
            default:
                break;
        }
        return next(action);
    };
};
export default socketMiddleware;

const SOCKET_CONNECTING = 0;
const SOCKET_OPEN = 1;

class Socket {
    constructor(onChange, onMessage, onDisconnect) {
        this.connect = (url) => {
            if (this.socket && (this.socket.readyState === SOCKET_OPEN || this.socket.readyState === SOCKET_CONNECTING)) {
                return;
            }
            this.socket = new WebSocket(url);
            this.socket.onopen = (event) => {
                this.onChange(true);
            };
            this.socket.onclose = (event) => {
                this.onChange(false);
                this.onDisconnect();
            };
            this.socket.onmessage = (event) => {
                this.onMessage(JSON.parse(event.data));
            };
        };
        this.sendMessage = (message) => {
            if (this.socket.readyState === SOCKET_OPEN) {
                this.socket.send(JSON.stringify(message));
            }
            else {
                console.error("Cannot emit socket messages. WebSocket not connected.");
            }
        };
        this.disconnect = () => this.socket && this.socket.close();
        this.onChange = onChange;
        this.onMessage = onMessage;
        this.onDisconnect = onDisconnect;
        this.socket = null;
    }
}

const INITIAL_STATE = {
    connected: false,
    shouldConnect: false
};

export const socketReducer = (state = INITIAL_STATE, action) => {
    let reduced;
    switch (action.type) {
        case CONNECTION_CHANGED:
            reduced = Object.assign({}, state, {
                connected: action.connected,
                isError: false
            });
            break;
        case REQUEST_SOCKET_CONNECT:
            return Object.assign(Object.assign({}, state), { shouldConnect: true });
        case REQUEST_SOCKET_DISCONNECT:
            return Object.assign(Object.assign({}, state), { shouldConnect: false });
        default:
            reduced = state;
    }
    return reduced;
};
