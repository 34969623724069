const getRootUrl = (stage) => {
    if (stage === "dev") return "https://apporders-api-dev.sugarlicious.mobi";
    else if (stage === "prod") return "https://apporders-api-prod.sugarlicious.mobi";

    // Default
    return "https://apporders-api-dev.sugarlicious.mobi";
};


const getAuthClientID = (stage) => {
    if (stage === "dev") return "ihschCpKoivhlCcX2WpKR3b2BLDmWJp2";
    else if (stage === "prod") return "ihschCpKoivhlCcX2WpKR3b2BLDmWJp2";
    return "ihschCpKoivhlCcX2WpKR3b2BLDmWJp2";
};

const getAuthAudience = (stage) => ("https://admin.sugarlicious.mobi");

const getWebsocketUrl = stage => {
    if (stage === 'dev') return 'wss://apporders-ws-dev.sugarlicious.mobi';
    else if (stage === 'prod') return 'wss://apporders-ws-prod.sugarlicious.mobi';

    // Default
    return 'wss://apporders-ws-dev.sugarlicious.mobi';
};

export const STAGE = process.env.REACT_APP_STAGE;
export const API_ROOT_URL = getRootUrl(STAGE);
export const AUTH_URL = "https://sugarlicious.eu.auth0.com";
export const AUTH_CLIENT_ID = getAuthClientID(STAGE);
export const AUTH_AUDIENCE = getAuthAudience(STAGE);
export const AUTH_CONNECTION = "Username-Password-Authentication";
export const WEBSOCKET_ROOT_URL = getWebsocketUrl(STAGE);
